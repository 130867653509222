import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { enqueueSnackbar } from "notistack";

export async function exportTokensToExcel(filteredData, filename = "Audiobooki") {

    if (filteredData.length === 0) {
        enqueueSnackbar("Brak danych do eksportu", { variant: "warning" });
        return;
    }

    var converted = filteredData.map((el) => {
        return {
            "ID": +el.token_id,
            "Format": (el.format === "AUDIOB") ? "Audiobook" : el.format,
            "Symbol": el.token_symbol,
            "Tytuł": el.name,
            "Utworzone": +el.mint,
            "Dostępne": +el.balance,
            "Wypożyczone": +el.borrowNow,
        }
    });
    const widths = [70, 70, 70, 500, 70, 70, 70];
    save_excel(converted, filename, widths);
}
export async function exportAudiobooksToExcel(filteredData, filename = "Audiobooki") {

    if (filteredData.length === 0) {
        enqueueSnackbar("Brak danych do eksportu", { variant: "warning" });
        return;
    }

    var converted = filteredData.map((el) => {
        return {
            "Token": el.token_symbol,
            "Id tokenu": +el.token_id,
            "Tytuł": decodeURIComponent(el.name).replace(/\+/g, " "),
            "Autor": decodeURIComponent(el.autor).replace(/\+/g, " "),
            "ISBN": el.isbn,
            "Ilość": +el.RazemInOut,
            "Półka": +el.Ballance,
            "Wypożyczeń": +el.sumaBorrow,
        }
    });
    const widths = [70, 70, 500, 180, 120, 70, 70, 70];
    save_excel(converted, filename, widths);
}

function save_excel(converted, filename, widths) {
    const ws = XLSX.utils.json_to_sheet(converted);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    // align last 3 columns right as numbers
    const wscols = widths.map((w, i) => ({ wpx: w }));
    ws['!cols'] = wscols;
    const range = XLSX.utils.decode_range(ws['!ref']);
    [1, 5, 6, 7].forEach(col => {
        for (let row = range.s.r + 1; row <= range.e.r; row++) {
            const cellAddr = XLSX.utils.encode_cell({ r: row, c: col });
            if (ws[cellAddr] && ws[cellAddr].t === 'n') {
                ws[cellAddr].z = '0';
            }
        }
    });

    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const blob = new Blob([wbout], { type: 'application/octet-stream' });
    saveAs(blob, `${filename}.xlsx`);
    var num = converted.length;
    enqueueSnackbar(`Eksport pliku Excel ${num} wierszy`, { variant: "success" });
}