import { withRouter } from "react-router";
import Background from "../components/Background";
import Button from "../components/Button";
import NavTopBar from "../components/NavTopBar";
import PlainContainer from "../components/PlainContainer";
import RegisterLibrary from "../components/RegisterLibrary/RegisterLibrary";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OverTableButtons from "../components/OverTableButtons";

const LibrariesFormView = ({ history }) => {
  return (
    <>
      <NavTopBar />
      <Background height="45vh" />
      <OverTableButtons
        widthInVw={95}
        heightInVh={4.1}
        customStyles={{ top: "15.5vh" }}
      >
        <Button
          text={
            <div style={{ display: "flex" }}>
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{
                  alignSelf: "center",
                  marginRight: "6px",
                }}
              />
              Powrót
            </div>
          }
          onClick={() => history.push(`/libraries`)}
          styleOptions={{
            height: "100%",
          }}
        />
      </OverTableButtons>
      <PlainContainer
        widthInVw={95}
        heightInVh={90}
        customStyles={{
          top: "19vh",
          bottom: "10vh",
          position: "absolute",
          left: "50%",
          transform: "translateX(-50%)",
          paddingBottom: "0px",
        }}
      >
        <RegisterLibrary />
      </PlainContainer>
    </>
  );
};

export default withRouter(LibrariesFormView);
