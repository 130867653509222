import React from "react";
import { useSelector } from "react-redux";

const LoggedUser = () => {
  const user = useSelector(state => state.authentication.user);

  return (
    <div
      style={{
        position: "absolute",
        right: "8vw",
        top: 50,
        lineHeight: "20px",
        fontSize: "calc(0.5vw + 6px)",
        textAlign: "center",
        color: "black",
        fontFamily: "Quicksand-Medium",
      }}
    >
      Użytkownik:<br /> {" "}
      <span
        style={{
          fontFamily: "Quicksand-Regular",
          fontWeight: "bold",
          textUnderlineOffset: "3px",
        }}
      >
        <u> {user.username}</u>
      </span>
    </div>
  );
};

export default LoggedUser;
