import React from "react";
import { connect } from "react-redux";
// import { logOut } from "../../../utils/logOut";
// import optionsIcon from './options.svg'
import logoutIcon from "../icons/logout.svg";
import TopBarButton from "./TopBarButton";
import onLogoutUser from "../redux/actions/onLogoutUser"

const TopBarButtonsPanel = ({ history, user }) => (
  <div
    style={{
      position: "absolute",
      top: "32px",
      right: "2vw",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",

    }}
  >
    {/* <TopBarButton iconSrc={optionsIcon} label="Options" /> */}
    <TopBarButton
      iconSrc={logoutIcon}
      label="Wyloguj"
      onClick={() => { onLogoutUser(history) }}
      width="calc(1.8vw + 16px)"
    />
  </div>
);


export default TopBarButtonsPanel;

